<template>
  <div class="artice_top widthCen content">
    <h6>当前位置：客服热线</h6>
    <div class="article_white">
      <div class="details">
        <!-- <h6>客服电话：888-888</h6>
        <div class="details_box">
          <img src="../../images/hot.png" />
        </div>-->
        <p v-html="content"></p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/styles/public.scss";
@import "@/styles/artice.scss";

.details {
  h6 {
    font-weight: 600;
    font-size: 18px !important;
  }

  .details_box {
    margin-bottom: 40px !important;
  }

  p {
    font-size: 16px;
    color: #666666;
    width: 80%;
    margin: 0 auto;
    line-height: 26px;
  }
}
</style>
<script>
export default {
  name: '',
  components: {},
  data() {
    return {
      content: ''
    };
  },
  created() {
    this.details();
  },
  methods: {
    details() {
      this.$api.getHotline().then(res => {
        var list = res.data;
        if (list.length > 0) {
          var obj = list[0];
          this.content = obj.Content;
        }
      });
    }
  }
};
</script>
